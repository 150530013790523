(function($) {
  $(function() {
    function setHover($slideshow) {
      var $pictures = $($slideshow.data('pictures'));
      var interval = null;

      $slideshow.find('.js-slideshow-slide').hover(function(e) {
        $slideshow.find('.c-bannerhome__slide--active').removeClass('c-bannerhome__slide--active');
        var $slide = $(this).closest('.c-bannerhome__slide');
        $slide.addClass('c-bannerhome__slide--active');

        var index = $slideshow.children().index($slide.get(0));
        $pictures.children().removeClass('c-bannerhome__picture--active').filter(':eq(' + index + ')').addClass('c-bannerhome__picture--active');

        if (!e.isTrigger) {
          clearInterval(interval);
        }
      });

      interval = setInterval(function() {
        var $next = $slideshow.find('.c-bannerhome__slide--active').next();
        if ($next.length == 0) {
          $next = $slideshow.find('.c-bannerhome__slide:eq(0)');
        }
        $next.find('.js-slideshow-slide').trigger('mouseenter');
      }, 2500);
    }

    $('.js-slideshow').each(function() {
      setHover($(this));
    })
  });
})(jQuery);
