(function($) {

  function shuffle(array) {
    var currentIndex = array.length,
      temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  function initItems($items) {
    if ($items.find('.js-shuffle-item').length <= 5) {
      return;
    }

    var $visibles = $items.find('.js-shuffle-item:eq(5)').prevAll();
    for (var i=0; i<$visibles.length; i++) {
      showRandom($items, $($visibles[i]));
    }
  }

  function showRandom($items, $visible) {
    var $invisibles = $items.find('.js-shuffle-item:eq(4)').nextAll(':not(.js-shuffle-progress)');
    $invisibles = shuffle($invisibles);

    var delay = randomIntFromInterval(15, 25) * 100;

    var $substitute = $invisibles.first();
    if ($substitute.length == 0) {
      setTimeout(function() {
        showRandom($items, $visible);
      }, delay);
      return;
    }
    $substitute.addClass('js-shuffle-progress');

    var replacedBackup = $visible.html();
    $visible.append($substitute.children().clone());
    $substitute.html(replacedBackup);

    setTimeout(function() {
      $visible.addClass('c-team__picture--hidding');
      $substitute.removeClass('js-shuffle-progress');
      setTimeout(function() {
        $visible.removeClass('c-team__picture--hidding');
        $visible.children().first().remove();
        showRandom($items, $visible);
      }, 1000);
    }, delay);
  }

  $(function() {
    $('.js-shuffle-items').each(function() {
      initItems($(this));
    });
  });
})(jQuery);
