//= require slick-carousel/slick/slick.js

$(function() {
  var conf = {
    achievementsslideshow: {
      slidesToShow: 1,
      infinite: true,
      fade: true,
      cssEase: 'linear',
      speed: 500
    },
    picturesslideshow: {
      slidesToShow: 1,
      infinite: true,
      fade: true,
      cssEase: 'linear',
      speed: 500
    },
    achievementstiles: {
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 670, // jusqu'à 670
          settings: {
            slidesToShow: 1
          }
        },
        {
          breakpoint: 960, // jusqu'à 960
          settings: {
            slidesToShow: 2
          }
        }
      ]
    },
    posts: {
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 960, // jusqu'à 960
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 780, // jusqu'à 780
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 620, // jusqu'à 620
          settings: {
            slidesToShow: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    }
  };

  $('.c-slick').each(function() {
    // return;
    var $this = $(this);

    var component = $this.data('slick-component');
    var $component = $this.closest('.c-' + component);
    var slick_class = 'c-' + component + '--slick';
    if ($component.hasClass(slick_class)) return;
    $component.addClass(slick_class);

    $this.slick($.extend({
      dots: false,
      infinite: false,
      speed: 300,
      prevArrow: $component.find('.js-slick-prev'),
      nextArrow: $component.find('.js-slick-next'),
    }, conf[component]));

    if (component == 'achievementsslideshow' || component == 'picturesslideshow') {
      $this.on('beforeChange', beforeChange);

      var slick = $this.slick('getSlick');
      setPosition($component, slick.$slides.eq(-1), slick.$slides.eq(1));
    }
  });

  function setPosition($component, $prev, $next) {
    var $prev_thumb = $component.find('.js-slick-prev-thumb');
    var $next_thumb = $component.find('.js-slick-next-thumb');

    $prev_thumb.fadeOut('fast', function() {
      $prev_thumb.html($prev.find('.js-slick-thumb').html());
      $prev_thumb.fadeIn('fast');
    });

    $next_thumb.fadeOut('fast', function() {
      $next_thumb.html($next.find('.js-slick-thumb').html());
      $next_thumb.fadeIn('fast');
    });
  }

  function beforeChange(event, slick, currentSlide, nextSlide) {
    if (nextSlide == 0) {
      var $prev = slick.$slides.eq(-1);
    }
    else {
      var $prev = slick.$slides.eq(nextSlide - 1);
    }

    if (nextSlide == (slick.$slides.length - 1)) {
      var $next = slick.$slides.eq(0);
    }
    else {
      var $next = slick.$slides.eq(nextSlide + 1);
    }

    var $component = slick.$slides.eq(0).closest('[class*="--slick"]');
    setPosition($component, $prev, $next);
  }
});
