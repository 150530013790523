(function($) {
  $(function() {
    var $force_blur = false;
    $('.js-auto-bur').on('mousedown', function() {
      $force_blur = false;
      var $this = $(this);
      if ($this.is(':focus')) {
        $force_blur = $this;
      }
    });
    $('.js-auto-bur').on('click', function() {
      if ($force_blur) {
        $force_blur.trigger('blur');
        $force_blur = false;
      }
    });
  })
})(jQuery);
